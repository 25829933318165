// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'
import OutLink from '@bodhi-project/components/lib/OutLink'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'General Policy & Admission Procedure',
  nakedPageSlug: 'general-policy-and-admission-procedure',
  pageAbstract:
    'Choosing the right school for our children is not an easy task. This is why we believe that the first step of the admission process should be to visit our school and get a feel of the school.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <h1 className="mask-h3">Welcome to the Admission Desk</h1>
        <p>
          Choosing the right school for our children is not an easy task. This
          is why we believe that the first step of the admission process should
          be to visit our school and get a feel of the school.
        </p>
        <p>
          Today, Cambridge Innovative School is an institution, where global
          education is seamlessly integrated with positive social values,
          coupled with a creative freedom that equips every student with
          necessary life skills.
        </p>
        <p>
          Our suggestion to all of you is to surf our school’s website
          thoroughly. The website spells out most of the information that
          parents and students need to know before applying. After checking the
          admission procedure, feel free to send us an e-mail for further
          details. We will be happy to give you all the information you require
          and to get you to know us better.
        </p>
        <p>We look forward to meeting you!</p>
        <p>
          Warm regards
          <br />
          The Admission Office
        </p>
        <h2 className="mask-h4">Dates for Admission</h2>
        <p>
          <strong>Admission for the session 2024-25 has been started</strong>.
          We are accepting the applications for all classes. For any further
          details you can contact us or visit our school.
        </p>
        <p>
          The deadline for admission is March, this can be subject to change. We
          will update our website for any changes in dates or information about
          the seats. If the seats have been filled, we will notify it on our
          official website and other media sources.
        </p>
        <h1 className="mask-h3">Some notes</h1>
        <p>
          We follow the regular process every year. The candidate must be
          registered prior to the closing admission date. The last date will be
          announced by the school. But usually this is in March. The process is:
        </p>
        <ul className="mask-p">
          <li>
            Every candidate must be registered prior to the date laid down each
            year by the school. The deadline is usually in March for admission
            into the School. There is a fee that must be paid on registration
            and this is non-refundable.
          </li>
          <li>
            In the admission for Grade I-VI, students have to show the Report
            card of the previous class. Examination for admission into Grade
            VII-XII takes place in March each year. All examinations are based
            on the CBSE Syllabus, so that there should be no need for a pupil to
            make extra preparation for them.
          </li>
          <li>
            Completed application form must be submitted at the school office
            within one week of issuance of the form.
          </li>
          <li>
            Issue of this application form or receipt / registration of a
            completed application does not imply admission. In all matters
            related to admission, the decision of the management of the school
            will be final.
          </li>
          <li>
            Parents and the students may be invited for an interaction with
            school management during the admission process. Dates for the same
            will be duly notified.
          </li>
        </ul>
        <h2 className="mask-h4">Prospectus</h2>
        <p>
          Please contact the school for the prospectus. A digital version of the
          prospectus is being prepared, and will be uploaded here soon.
        </p>
      </Col>
      <Col sm={24} md={8}>
        <h2 className="mask-h4">Admissions Enquiry</h2>
        <p>
          For assistance and help or to see our campus, please visit us at:
          <br />
          <i>Urban Estate, Phase II</i>
          <br />
          <i>Jalandhar, 144002</i>
          <br />
          <i>Punjab</i>
          <br />
          <i>India</i>
        </p>
        <p>
          You can also write an email to us at:
          <br />
          <OutLink to="mailto:admissions@cambridgeinnovativejal.school">
            <i>admissions@cambridgeinnovativejal.school</i>
          </OutLink>
        </p>
        <p>
          Or, you can call us at these numbers between 9AM and 2PM Monday to
          Friday:
          <br />
          <OutLink to="tel:0181-4623951">
            <i>0181-4623951</i>
          </OutLink>
          <br />
          <OutLink to="tel:0181-4623952">
            <i>0181-4623952</i>
          </OutLink>
          <br />
          <OutLink to="tel:0181-4623952">
            <i>0181-4623953</i>
          </OutLink>
        </p>
        <p>
          Do you wish to meet us and have a look around? You can request a
          guided tour by contacting us. Please note that the appointment is
          subject to availability.
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
